import http from "../plugins/http";
import store from "../store";

export default [
  {
    path: "/403",
    meta: {public: true},
    name: "AccessDenied",
    component: () => import(`@/views/error/Deny.vue`)
  },
  {
    path: "/500",
    meta: {public: true},
    name: "ServerError",
    component: () => import(`@/views/error/Error.vue`)
  },
  {
    path: "/",
    meta: {public: true},
    name: "Home",
    component: () => import(`@/views/main/Home.vue`)
  },
  {
    path: "/reports",
    meta: {public: true},
    name: "reports",
    component: () => import(`@/views/other/Reports/index.vue`)
  },
  {
    path: "/report/:path",
    meta: {public: true},
    name: "report",
    component: () => import(`@/views/other/Report/index.vue`),
    beforeEnter: async (to, from, next) => {
      try {
        if (!isNaN(to.params.path) && !isNaN(parseFloat(to.params.path))) {
          //если значение :path является числом то просто записываем его в локальное хранилище как setSourceId
          store.dispatch('setSourceId', to.params.path)
          next();
          return;
        } else {
          // если значение строка
          let response = await http.get(`/url/album/${to.params.path}`);
          // запрашиваем чпу юрл со строкой которую передали в path
          if (!!response.data) {
            // если есть ответ полученнный id записываем в sourceId
            store.dispatch('setSourceId', response.data.sourceId)
            next();
            return;
          } else {
            // если нет кидаем на 404
            next({name: '404'});
            return;
          }
        }
      } catch (error) {
        next({name: '404'});
        return;
      }
    }
  },
  {
    path: "/report/private/:id",
    meta: {public: true},
    name: "report-private",
    component: () => import(`@/views/other/PrivateReport/index.vue`)
  },
  {
    path: "/test",
    meta: {public: true},
    name: "test",
    component: () => import(`@/views/other/Test.vue`)
  },
  {
    path: "/videos",
    meta: {public: true},
    name: "videos",
    component: () => import(`@/views/other/Videos/index.vue`)
  },
  {
    path: "/video/:id",
    meta: {public: true},
    name: "video",
    component: () => import(`@/views/other/Video/index.vue`)
  },
  {
    path: "/establishment/:path",
    meta: {public: true},
    name: "establishment",
    component: () => import(`@/views/other/Establishment/index.vue`),
    beforeEnter: async (to, from, next) => {
      try {
        if (!isNaN(to.params.path) && !isNaN(parseFloat(to.params.path))) {
          //если значение :path является числом то просто записываем его в локальное хранилище как setSourceId
          store.dispatch('setSourceId', to.params.path)
          next();
          return;
        } else {
          // если значение строка
          let response = await http.get(`/url/establishment/${to.params.path}`);
          // запрашиваем чпу юрл со строкой которую передали в path
          if (!!response.data) {
            // если есть ответ полученнный id записываем в sourceId
            store.dispatch('setSourceId', response.data.sourceId)
            next();
            return;
          } else {
            // если нет кидаем на 404
            next({name: '404'});
            return;
          }
        }
      } catch (error) {
        next({name: '404'});
        return;
      }
    }
  },
  {
    path: "/establishments",
    meta: {public: true},
    name: "establishments",
    component: () => import(`@/views/other/Establishments/index.vue`)
  },
  {
    path: "/events",
    meta: {public: true},
    name: "events",
    component: () => import(`@/views/other/Events/index.vue`)
  },
  {
    path: "/event/:path",
    meta: {public: true},
    name: "event",
    component: () => import(`@/views/other/Event/index.vue`),
    beforeEnter: async (to, from, next) => {
      try {
        if (!isNaN(to.params.path) && !isNaN(parseFloat(to.params.path))) {
          //если значение :path является числом то просто записываем его в локальное хранилище как setSourceId
          store.dispatch('setSourceId', to.params.path)
          next();
          return;
        } else {
          // если значение строка
          let response = await http.get(`/url/event/${to.params.path}`);
          // запрашиваем чпу юрл со строкой которую передали в path
          if (!!response.data) {
            // если есть ответ полученнный id записываем в sourceId
            store.dispatch('setSourceId', response.data.sourceId)
            next();
            return;
          } else {
            // если нет кидаем на 404
            next({name: '404'});
            return;
          }
        }
      } catch (error) {
        next({name: '404'});
        return;
      }
    }
  },
  {
    path: "/news",
    meta: {public: true},
    name: "news",
    component: () => import(`@/views/other/News/index.vue`)
  },
  {
    path: "/news/:id",
    meta: {public: true},
    name: "newsPreview",
    component: () => import(`@/views/other/NewsPreview/index.vue`)
  },
  {
    path: "/image/:id",
    meta: {public: true},
    name: "image",
    component: () => import(`@/views/other/ImageView.vue`)
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFoundPage",
    component: () => import(`@/views/error/NotFound.vue`),
    meta: {public: true}
  },
];
