import Vue from 'vue';
import Router from 'vue-router';
import paths from './paths';
import store from '../store';

Vue.use(Router);

const router = new Router({
  base: '/',
  mode: 'history',
  linkActiveClass: 'active',
  routes: paths,
});

router.beforeResolve((to, from, next) => {
  if( store.state && store.state?.redirectsMap ) {
    const redirectTo = store.state.redirectsMap.get(to.path);
    if (redirectTo && redirectTo !== to.path) {
      next(redirectTo);
      return;
    }
  }
  // if (to.matched.some(record => (!record.meta.public && !record.meta.login))) {
  //   if (!store.getters.isLoggedIn) {
  //     next('/');
  //     return;
  //   }
  // } else {
  //   if (store.getters.isLoggedIn) {
  //     next('/dashboard');
  //     return;
  //   }
  // }
  next();
});

router.beforeEach((to, from, next) => {
  const canonicalLink = document.querySelector('link[rel="canonical"]');
  const defaultCanonical = location.protocol + '//' + location.host + location.pathname;

  if (canonicalLink) {
    canonicalLink.setAttribute('href', to.meta.canonicalUrl || defaultCanonical);
  } else {
    const link = document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', to.meta.canonicalUrl || defaultCanonical);
    document.head.appendChild(link);
  }

  next();
});

export default router;