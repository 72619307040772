import axios from 'axios';

const fileHttp = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? '/api/v1'
      : 'http://localhost:8083/api/v1',
  headers: {
    Accept: 'application/octet-stream',
    'Content-Type': 'application/octet-stream'
  },
  timeout: 180000
});


export default fileHttp;
