import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    globalErrorMessage: {},
    passwordRestore: null,
    videoDomain: process.env.NODE_ENV === 'production' ? '/api/v1/reports/video/stream/' : 'http://localhost:8083/api/v1/reports/video/stream/',
    loading: true,
    windowWidth: '',
    redirects: [],
    redirectsMap: null,
    search: '',
    currentCity: JSON.parse(localStorage.getItem('city')) || null,
    searchResult: {},
    status: '',
    token: localStorage.getItem('token') || '',
    currentBranch: localStorage.getItem('branch') || '',
    user: {},
    userId: localStorage.getItem('user_id') || null,
    updateUser: false,
    checkAccess: ({permission, c, r, u, d}) => false,
    refreshPageDataCallback: null,
    defaultRefreshPageDataCallback: () => {
      window.location.reload();
      history.go(0);
    },
    sourceId: JSON.parse(localStorage.getItem('sourceId')) || null,
    errorMessages: [],
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading';
    },
    set_password_restore(state, payload) {
      state.passwordRestore = payload;
    },
    set_loading(state, payload) {
      state.loading = payload;
    },
    auth_success(state, payload) {
      state.status = 'success';
      state.token = payload;
    },
    set_user(state, payload) {
      const hasAccess = (mask, c, r, u, d) => {
        let result = false;

        if (c) result |= Boolean(Number(mask) & 0b1000);
        if (r) result |= Boolean(Number(mask) & 0b0100);
        if (u) result |= Boolean(Number(mask) & 0b0010);
        if (d) result |= Boolean(Number(mask) & 0b0001);

        return !!result;
      }

      state.user = payload;
      state.checkAccess = ({permission, c, r, u, d}) => {
        let result = false;
        if (state.user && state.user.roles) {
          result = state.user.roles
            .filter(role =>
              role.active && role.permissions
                .filter(p => p.name.value === permission && hasAccess(p.operationPermissions, c, r, u, d))
                .length > 0)
            .length > 0
        }
        // console.debug('checkAccess', permission, result)
        return result;
      }
    },
    auth_error(state) {
      state.status = 'error';
    },
    logout(state) {
      state.status = '';
      state.token = '';
      state.user = {};
    },
    set_global_error_message(state, val) {
      state.globalErrorMessage = val
    },
    add_error_messages(state, message) {
      state.errorMessages.push({message: message, color: 'rgba(35,40,51,0.93)'})
      this.commit('close_messages')
    },
    add_success_messages(state, message) {
      state.errorMessages.push({message: message, color: 'rgba(76,175,80,0.93)'})
      this.commit('close_messages')
    },
    close_messages(state) {
      if (!!state.errorMessages && Array.isArray(state.errorMessages) && state.errorMessages.length > 0) {
        setTimeout(() => {
          state.errorMessages = state.errorMessages.slice(0, -1)
        }, 3000);
      }
    },
    select_branch(state, value) {
      state.currentBranch = value
      localStorage.setItem('branch', JSON.stringify(value));
      if (!state.currentBranch) {
        JSON.parse(localStorage.getItem('branch'))
      }
    },
    search_word(state, value) {
      state.search = value
    },
    clear_search_word(state, value) {
      state.search = value
    },
    set_search_result(state, payload) {
      state.searchResult = payload;
    },
    set_user_id(state, payload) {
      state.userId = payload
      localStorage.setItem('user_id', payload)
    },
    update_user(state) {
      state.updateUser = !state.updateUser
    },
    set_city(state, payload) {
      state.currentCity = payload
      localStorage.setItem('city', JSON.stringify(payload))
    },
    set_source_id(state, payload) {
      state.sourceId = payload
      localStorage.setItem('sourceId', JSON.stringify(payload))
    },
    window_width(state, payload) {
      state.windowWidth = payload
    }
  },
  actions: {
    setWindowWidth(context, val) {
      context.commit('window_width', val);
    },
    setLoading(context, val) {
      context.commit('set_loading', val);
    },
    setPasswordRestore(context, val) {
      context.commit('set_password_restore', val);
    },
    setGlobalErrorMessage(context, message) {
      if (message === undefined) {
        context.commit('set_global_error_message', {
          message: 'Something went wrong',
          error: null
        });
      } else if (message instanceof Error) {
        console.error(message)
        context.commit('set_global_error_message', {
          message: 'Something went wrong',
          error: message
        });
      } else {
        context.commit('set_global_error_message', {
          message: message,
          error: null
        });
      }
    },

    init(context) {
      const token = localStorage.getItem('token');
      if (token) {
        Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token;

        this.commit('auth_success', token);

        // Vue.prototype.$http.get('/user/current')
        //     .then(value => this.commit('set_user', value.data))
      }
    },

    login({commit, dispatch}, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        Vue.prototype.$http.post('/auth/signin', user)
          .then(resp => {
              this.commit('set_user_id', resp.data.id)
              if (resp.data.token) {
                localStorage.setItem('token', resp.data.token);
                dispatch('init');

                resolve(resp);
              } else {
                reject(resp.data.message || 'Ошибка! Обратитесь к администратору.');
              }
            },
            err => {
              commit('auth_error');
              localStorage.removeItem('token');
              reject(err.response?.data?.message);
            })
          .catch(err => {
            localStorage.removeItem("token");
            reject(err);
          })
        ;
      })
    },
    logout({commit}) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        commit('logout');
        localStorage.removeItem('token');
        delete Vue.prototype.$http.defaults.headers.common['Authorization'];
        resolve();
      });
    },
    addErrorMessages(context, message) {
      context.commit('add_error_messages', message);
    },
    addSuccessMessages(context, message) {
      context.commit('add_success_messages', message);
    },
    selectBranch(context, branch) {
      context.commit('select_branch', branch)
    },
    selectSearchWord(context, word) {
      context.commit('search_word', word)
    },
    clearSearchWord(context, word) {
      context.commit('clear_search_word', word)
    },
    setSearchResult(context, val) {
      context.commit('set_search_result', val);
    },
    updateUser(context, val) {
      context.commit('update_user')
    },
    setCity(context, val) {
      context.commit('set_city', val)
    },
    setSourceId(context, val) {
      context.commit('set_source_id', val)
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    user: state => state.user,
    userId: state => state.userId,
    updateUser: state => state.updateUser,
    username: state => state.user.email,
    globalErrorMessage: state => state.globalErrorMessage,
    globalErrorDialog: state => state.globalErrorMessage && state.globalErrorMessage.message,
    checkAccess: state => state.checkAccess,
    allRoles: state => state.user.roles || [],
    activeRole: state => (state.user.roles || []).filter(role => role.active).values().next().value,
    errorMessages: state => state.errorMessages,
    currentBranch: state => state.currentBranch,
    passwordRestore: state => state.passwordRestore,
    loading: state => state.loading,
    searchResult: state => state.searchResult,
    currentCity: state => state.currentCity,
    sourceId: state => state.sourceId,
    windowWidth: state => state.windowWidth,
    videoDomain: state => state.videoDomain
  }
});
