<template>
  <div id="appRoot" :class="{'dark-theme': $vuetify.theme.dark}" v-on:resize="handleResize">
    <template v-if="$route.meta.public">
      <v-app  style="overflow: hidden" id="inspire" class="app">
        <v-main class="pa-0 ma-0">
          <v-row ref="autoScaleElement" class="pa-0 ma-0">
            <v-col :style="dynamicBackgroundColor" style="min-height: 60vh" cols="12" class="pa-0 d-flex justify-center">
              <router-view/>
            </v-col>
            <v-col :style="dynamicBackgroundColorFooter" cols="12" class="pa-0">
              <template v-if="windowWidth > 1400">
                <v-lazy transition="fade-transition" :value="() => import('@/components/Footer/AppFabDesktop.vue')">
                  <app-fab-desktop/>
                </v-lazy>
              </template>
              <template v-if="windowWidth <= 1400">
                <v-lazy transition="fade-transition" :value="() => import('@/components/Footer/AppFabMobile.vue')">
                  <app-fab-mobile/>
                </v-lazy>
              </template>
            </v-col>
          </v-row>
        </v-main>
      </v-app>
    </template>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import AppToolbar from "@/components/AppToolbar.vue";
import AppFabDesktop from "@/components/Footer/AppFabDesktop.vue";
import AppFabMobile from "@/components/Footer/AppFabMobile.vue";


export default {
  components: {
    AppFabMobile,
    AppFabDesktop,
    AppToolbar,
  },
  data: () => ({
    lang: '',
    width: 0,
    expanded: true,
    rightDrawer: false,
    showDrawer: false,
    snackbar: {
      show: false,
      text: "",
      color: ""
    },
    drawer: false,
    dialog: false,
    trackNum: '',
    dynamicBackgroundColor: '',
    dynamicBackgroundColorFooter: '',
  }),
  mounted() {
      this.dynamicBackgroundColor = this.$vuetify.theme.dark ? 'background: #171717 !important' : 'background: #F5F5F5 !important';
    this.dynamicBackgroundColorFooter = this.$vuetify.theme.dark ? 'background: #171717 !important' : 'background: #FFFFFF !important';
  },
  computed: {
    ...mapGetters(['loading', 'windowWidth', 'modalWidth', 'globalErrorMessage', 'globalErrorDialog', 'errorMessages']),
    currentRouteName() {
      return this.$route.name;
    }
  },
  watch: {
    width() {
      this.setWindowWidth(this.width);
    },
    '$vuetify.theme.dark'(newVal) {
      this.dynamicBackgroundColor = newVal ? 'background: #171717' : 'background: #F5F5F5';
      this.dynamicBackgroundColorFooter = newVal ? 'background: #171717' : 'background: #FFFFFF';
    }
  },
  methods: {
    ...mapActions(['init', 'setWindowWidth', 'setGlobalErrorMessage']),
    handleResize() {
      this.width = document.body.clientWidth;

      let baseWidth = 1440;

      if (this.width < 1920) {
        baseWidth = this.width * 0.75;
      }

      this.updateElementSize(baseWidth);
    },
    updateElementSize(width) {
      const element = this.$refs.autoScaleElement;

      element.style.width = `${width}px`;
      element.style.height = '100%'; // Можете установить свои значения в зависимости от дизайна
    },
    getWidth() {
      window.onresize = () => {
        this.width = document.body.clientWidth;
      };
    },
    handleDrawerVisible() {
      this.showDrawer = !this.showDrawer
    },

    fetchRedirects() {
      this.$http.get('/seo/redirect')
        .then(r => {
          this.$store.state.redirects = r.data.content;
          this.$store.state.redirectsMap = new Map(r.data.content.map(item => [item.from, item.to]));
        })
        .catch(e => {
          console.error(e);
        });
    }
  },
  created() {
    this.init();
    this.getWidth();
    let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/png';
    link.rel = 'shortcut icon';
    link.href = '/favicon.png';
    document.getElementsByTagName('head')[0].appendChild(link);


    this.width = document.body.clientWidth;
    // AppEvents.forEach(item => {
    //   this.$on(item.name, item.callback);
    // });
    window.getApp = this;


    // this.$http.interceptors.response.use(
    //     response => {
    //       return response;
    //     },
    //     err => {
    //       return new Promise((resolve, reject) => {
    //         if (err?.response?.status === 401 || err?.response?.status === 403) {
    //           this.$store.dispatch("logout");
    //           this.$router.push("/login");
    //         } else {
    //           reject(err);
    //         }
    //       });
    //     }
    // );

    this.fetchRedirects();
  }
};
</script>


<style lang="scss">

</style>