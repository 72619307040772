import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import vuetify from "./plugins/vuetify";
import router from "./router";
import http from "./plugins/http";
import http2 from "./plugins/http2";
import fileHttp from "./plugins/fileHttp";
import mediaHttp from "./plugins/mediaHttp";
import rules from "@/api/rules";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Meta from 'vue-meta';
import VueMarqueeSlider from 'vue-marquee-slider';
import {LMap, LTileLayer, LMarker} from 'vue2-leaflet';
import VueLazyload from 'vue-lazyload'
import VueScrollTo from 'vue-scrollto'
import 'leaflet/dist/leaflet.css';
import "./plugins/vuetify";
import "@mdi/font/css/materialdesignicons.css";
import "@/scss/style.scss";
import {createYmapsVue2} from 'vue-yandex-maps';


import HeroIcon from 'vue-heroicons'
import {
  dashboard,
  reports,
  video,
  establishments,
  news,
  banners,
  contacts,
  settings,
  notification,
  users,
  pencil,
  trash,
  roles,
  eye,
  picture,
  acc,
  logout,
  kitchen,
  phone,
  address,
  check,
  time,
  lang,
  cities,
  archive,
  widgets,
  menu,
  camera,
  photographer,
  mobilephone,
  poster,
  kg,
  book,
  arrowUp,
  arrowRight,
  smallArrowRight,
  smallArrowDown,
  filter,
  heart,
  share,
  hours,
  global,
  marker,
  money,
  phone2,
  phone3,
  smartphone,
  calendar,
  receipt,
  arrowRight2,
  arrowLeft2,
  burger,
  search,
  cross,
  inst, instagram, callPhone
} from './icons/icons'

HeroIcon.add([
  dashboard,
  reports,
  video,
  establishments,
  news,
  banners,
  contacts,
  settings,
  notification,
  users,
  pencil,
  trash,
  roles,
  eye,
  picture,
  acc,
  logout,
  phone,
  address,
  check,
  time,
  lang,
  cities,
  archive,
  widgets,
  menu,
  camera,
  photographer,
  mobilephone,
  poster,
  kg,
  book,
  arrowUp,
  arrowRight,
  smallArrowRight,
  smallArrowDown,
  filter,
  heart,
  share,
  kitchen,
  hours,
  global,
  marker,
  money,
  phone2,
  phone3,
  smartphone,
  calendar,
  receipt,
  arrowRight2,
  arrowLeft2,
  burger,
  search,
  cross,
  inst,
  instagram,
  callPhone
])
Vue.use(HeroIcon)


Vue.config.productionTip = false;
Vue.prototype.$http = http;
Vue.prototype.$http2 = http2;
Vue.prototype.$mediaHttp = mediaHttp;
Vue.prototype.$fileHttp = fileHttp;
Vue.prototype.$rule = rules;

AOS.init({
  once: true
})

// const token = localStorage.getItem("token");
// if (token) {
//     http.defaults.headers.common["Authorization"] = "Bearer " + token;
// }


Vue.config.productionTip = false;


window.dbg = (data) => {
  console.debug(data)
  return data;
}


window.tbl = (data) => {
  console.table(JSON.parse(JSON.stringify(data)))
  return data;
}
Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})
Vue.use(createYmapsVue2({
  apikey: '5198c492-5bbc-4b98-9f60-61aa70cbd2a2',
}));
Vue.use(VueScrollTo)
Vue.use(VueMarqueeSlider)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
  listenEvents: ['scroll']
});
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");